var elem = $.jInvertScroll(['.scroll'],        // an array containing the selector(s) for the elements you want to animate
    {
    height: 8000,                   // optional: define the height the user can scroll, otherwise the overall length will be taken as scrollable height
    onScroll: function(percent) {   //optional: callback function that will be called when the user scrolls down, useful for animating other things on the page
        // console.log(percent);
    }
});

var debounce = function(wait, immediate, func) {
	var timeout;

	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

var addEndEvent = debounce(200, false, function(e) {
	$(this).trigger(e.type + 'End');
});

var is_mobile = function() {

	if ($(window).width() <= 768) {
	    elem.destroy();
	    $('html').addClass('is_mobile');
	  }
	  else {
	    elem.reinitialize();
	    $('html').removeClass('is_mobile');
	  }
};


var bindEvents = function() {
	$(window).on('resize orientationchange', addEndEvent);
	$(window).on('resizeEnd', resizeHandler);
	// $(window).on('scroll', scrollHandler);
	// $('body').on('reveal', '.scroll-reveal', scrollRevealHandler);
};

var resizeHandler = function() {
	is_mobile();
};

$(window).resize(function() {
	  if ($(window).width() <= 768) {
	    elem.destroy();
	    $('html').addClass('is_mobile');
	  }
	  else {
	    elem.reinitialize();
	    $('html').removeClass('is_mobile');
	  }
	});

$(document).ready(function() {

	/*
	 ######     #######    #######   ##    ##   ####  ########   
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##         ##     ##  ##     ##  #####       ##   ######     
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	 ######     #######    #######   ##    ##   ####  ########   
	*/
	$.cookieBar({
		message:       "Nous diffusons des cookies afin d’analyser le trafic sur ce site. Les informations concernant l’utilisation que vous faites de notre site nous sont transmises dans cette optique.",
		acceptText:    "J’accepte",
		declineButton: true,
		declineText:   'Je refuse',
		expireDays:    30,
		fixed:         true,
		zindex:        10000
	});

	if(jQuery.cookieBar('cookies')){
		//Google Analytics or other code here
	}

	FastClick.attach(document.body);

	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	// Création des évènements
	bindEvents();

	// test mobile et reinitialisation scroll horizontal
	is_mobile();
	

   //  if ($(window).width() <= 768) {
	  //   $('html').addClass('is_mobile');
	  // }
	  // else {
	  //   $('html').removeClass('is_mobile');
	  // }
	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	// $('.contact-link a').on('click', function(e) {
	// 	e.preventDefault();
	// 	var target        = $(this).attr('href');
	// 	var pos           = $('body').height();
	// 	$('html, body').animate({ scrollTop: pos +'px'}, 800);
	// });

	$('.logo a').on('click', function(e) {
		e.preventDefault();
		var target        = $(this).attr('href');
		var pos           = $('body').height();
		$('html, body').animate({ scrollTop: pos -'px'}, 800);
	});

	$(".btn-form").click(
		function() {
			$('.form-container').toggleClass('form-container--show ');
		}
	);

	$(".form-cross").click(
		function() {
			$('.form-container').toggleClass('form-container--show ');
		}
	);


	// $('#contact-js').click(function(){

	//     $('html, body').animate({
	//         scrollTop: $( $(this).attr('href') ).offset().top
	//     }, 500);

 //    return false;
	// });

	// /*
	//  ######     #######   ##    ##   ########      ###      ######    ########   
	// ##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	// ##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	// ##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	// ##         ##     ##  ##  ####      ##      #########  ##            ##      
	// ##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	//  ######     #######   ##    ##      ##      ##     ##   ######       ##      
	// */

	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	$("#contact").submit(function(){
		if($("#contact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/",
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});
});
